import axios from 'axios';

import { authInterceptor, BASE_API_URL, timezoneInterceptor } from './index';

export const authorizedReq = axios.create();
authorizedReq.interceptors.request.use(...authInterceptor);
authorizedReq.interceptors.request.use(...timezoneInterceptor);

export const applyCommonActionWithUri = (uri) =>
  authorizedReq({
    method: 'GET',
    url: `${BASE_API_URL}/${uri}`,
  });

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + '/user/details');

export const setTimeZone = (params) =>
  authorizedReq.put(BASE_API_URL + '/user/update-timezone/', params);
